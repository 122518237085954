import React from "react";

// Customizable Area Start
import HomeIcon from '@mui/icons-material/Home';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import TimelineIcon from '@mui/icons-material/Timeline';
import SubdirectoryArrowLeftOutlinedIcon from '@mui/icons-material/SubdirectoryArrowLeftOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { styled } from "@mui/styles";
import { logoImage } from "./assets";

interface NavItemProps {
  isActive?: boolean;
}
// Customizable Area End

import SideNavController, {
  Props,
} from "./SideNavController";

export default class SideNav extends SideNavController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { showPermissions } = this.state;
    const sideData = [
      {
        id: 1,
        path: "/",
        text: "Dashboard",
        icon: <HomeIcon />
      },
      {
        id: 2,
        path: "Cfpipelines1",
        text: "Pipelines",
        icon: <BarChartRoundedIcon />
      },
      {
        id: 3,
        text: "Permissions",
        icon: <LockOutlinedIcon />,
        nestedItems: [
          {
            id: 4,
            path: "Taskgroups",
            text: "Task Groups",
            icon: <ListAltIcon />
          },
          {
            id: 5,
            path: "Rolesandpermissions2",
            text: "Pipelines",
            icon: <BarChartRoundedIcon />
          }
        ]
      },
      {
        id: 6,
        path: "Triggers",
        text: "Triggers",
        icon: <PriorityHighRoundedIcon />
      },
      {
        id: 7,
        path: "Actions",
        text: "Actions",
        icon: <TimelineIcon />
      },
      {
        id: 8,
        path: "tasks",
        text: "Tasks",
        icon: <ListAltIcon />
      },
    ];
    const settingsData = [
      {
        path: "Settings2",
        text: "Settings",
        icon: <SettingsOutlinedIcon />
      },
      {
        path: "logout",
        text: "Log Out",
        icon: <SubdirectoryArrowLeftOutlinedIcon />
      },
    ];

    return (
      <Container>
        <div style={{ width: "80%" }}>
          <Logo onClick={() => this.navigate("/")} data-test-id="logobutton">
            <LogoImage src={logoImage.default} alt="logo" />
          </Logo>
          <Divider />
          {sideData.map((item) => (
            <div key={item.id}>
              <NavItem
                onClick={item.nestedItems ? this.togglePermissions : () => this.navigate(item.path)}
                isActive={
                  (item.path && this.isActive(item.path)) ||
                  (item.nestedItems && showPermissions && item.nestedItems.some(nested => this.isActive(nested.path)))
                }
                data-test-id="navigation-item"
              >
                <NavIcon isActive={item.path ? this.isActive(item.path) : showPermissions}>
                  {item.icon}
                </NavIcon>
                <NavText isActive={item.path ? this.isActive(item.path) : showPermissions}>{item.text}</NavText>
              </NavItem>
              {item.nestedItems && showPermissions && (
                <NestedItems>
                  {item.nestedItems?.map(nestedItem => (
                    <NavItem
                      key={nestedItem.id}
                      onClick={() => this.navigate(nestedItem.path)}
                      isActive={this.isActive(nestedItem.path)}
                      data-test-id="nested-navigation-item"
                    >
                      <NavText style={{ fontSize: '16px' }} isActive={this.isActive(nestedItem.path)}>{nestedItem.text}</NavText>
                    </NavItem>
                  ))}
                </NestedItems>
              )}
            </div>
          ))}

        </div>
        <div style={{ width: "80%" }}>
          <Divider />
          {settingsData.map((item) => (
            <SettingsContainer
              key={item.path}
              onClick={() => {
                if(item.path=="logout"){
                  localStorage.removeItem("token")
                  localStorage.removeItem("pipeline")

                  this.navigate("./EmailAccountLoginBlock")
                }
                this.navigate(item.path)}}
              isActive={this.isActive(item.path)}
              data-test-id="settings-icon"
            >
              <NavIcon isActive={this.isActive(item.path)}>
                {item.icon}
              </NavIcon>
              <NavText isActive={this.isActive(item.path)}>{item.text}</NavText>
            </SettingsContainer>
          ))}
        </div>
      </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Container = styled("div")({
  paddingTop: "20px",
  display: "flex",
  position: "sticky",
  top: "10px",
  fontFamily: "Raleway",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#1A4A42E5",
  height: "calc(100vh - 20px)",
  boxSizing: "border-box",
  width: "100%",
  borderRadius: "12px",
  boxShadow: "rgba(99, 99, 99, 0.6) 0px 2px 8px 0px",
  "@media (max-width: 640px)": {
    width: "60px",
  }
});

const Logo = styled("div")({
  cursor: "pointer",
  backgroundColor: "rgba(26, 74, 66, 0.9)",
  padding: "10px",
  borderRadius: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const LogoImage = styled("img")({
  "@media (max-width: 480px)": {
    width: "50px"
  },
  "@media (min-width: 481px) and (max-width: 767px)": {
    width: "70px"

  },
  "@media (min-width: 768px) and (max-width: 1024px)": {
    width: "100px"
  },
  "@media (min-width: 1025px) and (max-width: 1280px)": {
    width: "120px"
  },
  "@media (min-width: 1281px) and (max-width: 1440px)": {
    width: "160px"
  },
  "@media (min-width: 1441px)": {
    width: "300px",

  }
})

const Divider = styled("hr")({
  width: "100%",
  margin: "10px 0",
  backgroundColor: "#E46760",
  color: "#E46760",
  boxSizing: "border-box"
});

const NavItem = styled("div")(({ isActive }: NavItemProps) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  cursor: "pointer",
  marginBottom: "17px",
  color: isActive ? "#E46760" : "#FFFFFF",
  fontWeight: isActive ? "700" : "500",
  "@media (max-width: 640px)": {
    justifyContent: "center"
  },
  "@media (min-width: 481px) and (max-width: 767px)": {
    fontSize: "10px"
  },
  "@media (min-width: 768px) and (max-width: 1024px)": {
    fontSize: "12px"
  },
  "@media (min-width: 1025px) and (max-width: 1280px)": {
    fontSize: "18px"
  },
  "@media (min-width: 1281px) and (max-width: 1440px)": {
    fontSize: "20px"

  },
  "@media (min-width: 1441px)": {
    fontSize: "35px"
  }
}));

const NavIcon = styled("div")(({ isActive }: NavItemProps) => ({
  color: isActive ? "#E46760" : "#FFFFFF",
  height: "100%"
}));

const NavText = styled("p")(({ isActive }: NavItemProps) => ({
  margin: 0,
  color: isActive ? "#E46760" : "#FFFFFF",
  fontWeight: isActive ? "700" : "500",
  "@media (max-width: 640px)": {
    display: "none",
  }
}));
const NestedItems = styled("div")({
  paddingLeft: "35px",
  display: "flex",
  flexDirection: "column",
});
const SettingsContainer = styled("div")(({ isActive }: NavItemProps) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  cursor: "pointer",
  marginBottom: "17px",
  width: "100%",
  color: isActive ? "#E46760" : "#FFFFFF",
  fontWeight: isActive ? "700" : "500",
  "@media (max-width: 640px)": {
    justifyContent: "center"
  },
  "@media (min-width: 481px) and (max-width: 767px)": {
    fontSize: "10px"
  },
  "@media (min-width: 768px) and (max-width: 1024px)": {
    fontSize: "12px"
  },
  "@media (min-width: 1025px) and (max-width: 1280px)": {
    fontSize: "18px"
  },
  "@media (min-width: 1281px) and (max-width: 1440px)": {
    fontSize: "20px"

  },
  "@media (min-width: 1441px)": {
    fontSize: "35px"
  }
}));
// Customizable Area End
