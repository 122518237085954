// Customizable Area Start
import React from "react";
import { Box, Typography as MuiTypography, IconButton } from '@mui/material'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PaginationComponent from "./utils/PaginationComponent";
import ActionsController, {Props,ActionsData} from "./ActionsController";
import { LoadingSpinner } from "./CreatePipeline.web";
import { SearchBar } from "./Cfpipelines1.web";
// Customizable Area End

export default class Actions extends ActionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderColumnTypography = (flex: number, text: string | number) => {
    return (
      <MuiTypography variant="h6" style={{ fontSize: '1.4rem', flex: flex, fontWeight: 700, color: '#3E3E3E' }}>{text}</MuiTypography>
    )
  }

  renderRowTypography = (flex: number, text: string | number, row: ActionsData) => {
    return (
      <MuiTypography variant="h6" data-test-id={"row-typography"+flex} style={{
        fontSize: '1.4rem',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        flex: flex, fontWeight: 600, cursor: 'pointer', color: '#203447'
      }} onClick={() => this.handleActionsNavigate(row)}>{text}</MuiTypography>
    )
  }
  
  actionsList = () => {
    const { currentPage, pageSize, filteredData } = this.state;
    const paginatedRows = filteredData.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
    const startIndex = currentPage * pageSize + 1;
    const endIndex = Math.min((currentPage + 1) * pageSize, filteredData.length);
    const totalResults = filteredData.length;
    return <Box sx={listContainer}>
      <Box sx={headingStyle}>
        <MuiTypography variant="h6" sx={headingTextStyle}>
          Actions
        </MuiTypography>
        <Box display='flex' alignItems='center' gap={1}>
        <SearchBar data-test-id="btnNav" handleSearchField={this.handleSearchActions} />
        </Box>
      </Box>
      <Box display="flex" flexDirection='column' gap={1}>
        <Box display="flex" style={listHeadingStyle}>
          {this.renderColumnTypography(6, 'Action Name')}
          {this.renderColumnTypography(10, 'Description')}
          {this.renderColumnTypography(1.5, 'Actions')}
        </Box>
        {paginatedRows.length > 0 ? paginatedRows.map(row => (
          <Box display='flex' key={row.id} sx={listContentStyle}>
            {this.renderRowTypography(6, row.action_name, row)}
            {this.renderRowTypography(10, "test", row)}
            <Box style={{flex:1.5}}>
              <IconButton data-test-id="btn-eye" sx={addButtonStyle} style={{ padding: "0.6rem" }}>
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        )) : <Box display='flex' justifyContent='center' alignItems='center'><MuiTypography variant="h5">No Actions Found</MuiTypography></Box>}
      </Box>
      <PaginationComponent data-test-id="paginationActions" currentPageIndex={currentPage} requiredPageSize={pageSize} startIndex={startIndex} endIndex={endIndex} totalResults={totalResults} handlePageChange={this.handlePageChange}/>     
    </Box>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={pipelineListContainerStyle}>
        {this.state.isLoading ?
        <LoadingSpinner />
                :
      this.actionsList()}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const pipelineListContainerStyle = {
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  boxSizing: "border-box",
};
const dividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};

const addButtonStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "1rem",
  borderRadius: "0.8rem",
};
const listContainer={ display: "flex", flexDirection: "column" }
const headingStyle={ display: "flex", marginBottom: '1.6rem', justifyContent: "space-between", alignItems: 'center', flexWrap: 'nowrap' }
const headingTextStyle={ fontWeight: 700, fontSize: '2.2rem', color: "#292524" }
const listHeadingStyle={alignItems: 'center', backgroundColor: '#29252414', padding: '0.5rem 1rem', borderRadius: ' 0.8rem' }
const listContentStyle={ padding: '0.5rem 1rem', borderBottom: '0.1rem solid #1A4A421A', alignItems: 'center' }

// Customizable Area End
