import React, { Component } from "react";
import { Box, Button, Typography, Divider, TextField, Autocomplete, InputAdornment } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search'
import { UserTypeWithLabel } from "./generateTasks";

interface CreateEditTaskErrorsType {
    group_name?: string
    description?: string
}
interface Props {
    navigation: any;
    id: string;
    title: string;
    group_name: string;
    taskId: string;
    handleChange: (e: any) => void;
    handleAutoComplete: (id: string) => (e: any, newValue: any) => void
    selectedLevel: {label:string}[]
    allLevels: string[]
    users: UserTypeWithLabel[]
    selectedUsers: UserTypeWithLabel[]
    pipelines: string[]
    selectedPipelines: {label:string}[]
    description: string
    handleCreateEdit: () => void
    isCreateEditFormComplete: () => boolean | string
    createEditErrors: CreateEditTaskErrorsType
    buttonText: string
}

interface S {
}

interface SS {
}

export default class Customtaskgroup extends Component<Props> {
    constructor(props: any) {
        super(props);
    }

    renderAutoComplete = (title: string, id: string, options: any, value: any) => {
        return (
            <Box>
                <Typography
                    variant="body1"
                    style={{
                        margin: "0.5rem 0",
                        color: "#1A4A42",
                        fontWeight: 700,
                        fontSize: "1.4rem",
                    }}
                >
                    {title}
                </Typography>
                <Autocomplete
                    multiple
                    id={id}
                    options={options}
                    onChange={(event, newValue) => this.props.handleAutoComplete(id)(event, newValue)}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    value={value}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => {
                        return (
                            <li
                                {...props}
                                style={{
                                    fontSize: "1.6rem",
                                    backgroundColor: selected ? "#1A4A421A" : "transparent",
                                    fontWeight: 700,
                                    color: selected ? "#1A4A42" : "#0F172A",
                                }}
                            >
                                {option.label}
                            </li>
                        );
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                        <TextField
                            style={{ marginBottom: "0.8rem" }}
                            {...params}
                            placeholder={
                                Array.isArray(params.InputProps.startAdornment) &&
                                    params.InputProps.startAdornment.length > 0
                                    ? ""
                                    : "select"
                            }
                            InputLabelProps={{ shrink: false }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end"
                                        sx={{
                                            "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                                paddingRight: "0 !important"

                                            }
                                        }}
                                    >
                                        <SearchIcon sx={{
                                            color: '#64748B',
                                            cursor: 'pointer',
                                            fontSize: '2rem',
                                            "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                                paddingRight: "0 !important"

                                            }
                                        }} />
                                    </InputAdornment>
                                ),
                                sx: {
                                    fontWeight: 400,
                                    paddingLeft: "0.3rem !important",
                                    fontSize: "1.6rem",
                                    "& .css-18bmrv6-MuiButtonBase-root-MuiChip-root": {
                                        fontSize: "1.6rem",
                                        paddingRight: "0",
                                        display: 'none'
                                    },
                                    "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                        paddingRight: "0 !important"

                                    },
                                    "& .MuiAutocomplete-tag":{
                                        display:"none"
                                    }

                                },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    padding: "0 0.3rem",
                                },
                                '& .MuiSelect-icon': {
                                    display: 'none',
                                },
                                "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root": {
                                    paddingRight: "0 !important"
                                },
                                "& .MuiInputAdornment-root": {
                                    marginRight: '1rem',
                                },
                                "&.MuiInputBase-root.MuiOutlinedInput-root": {
                                    paddingRight: "0 !important",
                                },
                                "& .MuiInputBase-adornedEnd": {
                                    paddingRight: "0 !important",
                                },
                                "& .MuiAutocomplete-inputRoot": {
                                    paddingRight: "0 !important",
                                },

                            }}
                        />
                    )}
                />
                <Box sx={{ marginTop: "0.1rem", display: "flex", gap: "0.5rem", flexWrap: 'wrap', alignItems: 'center' }}>
                    {value.map((item: any, index: number) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                border: '0.1rem solid #1A4A4233',
                                backgroundColor: "white",
                                borderRadius: "1rem",
                                padding: "1rem",
                                fontSize: "1.2rem",
                                fontWeight: 400,
                                color: "#203447",
                            }}
                        >
                            {item.source ? <Box display="flex" alignItems="center" gap={1}>
                                <img
                                    src={item.source}
                                    style={{ width: '18px', height: '18px', borderRadius: '50%', objectFit: 'cover' }}
                                />
                                {item.label}
                            </Box>
                                :
                                item.label}
                            <CloseIcon
                                sx={{
                                    marginLeft: "0.5rem",
                                    fontSize: "1.6rem",
                                    cursor: "pointer",
                                    color: "#1A4A4280"
                                }}
                                onClick={() =>
                                    this.props.handleAutoComplete(id)(null, value.filter((v: any) => v !== item))
                                }
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    };

    render() {
        const { navigation, buttonText, createEditErrors, title, group_name, taskId, handleChange, allLevels, isCreateEditFormComplete, selectedLevel, handleCreateEdit, users, selectedUsers, pipelines, selectedPipelines, description } = this.props
        const levels = allLevels.map(item => { return { label: item } })
        const pipelineData = pipelines.map(pipeline => { return { label: pipeline } })

        return (
            <Box sx={{ ...createEditAccountGroupContainer }}>
                <Box marginBottom={2}>
                    <Box display='flex' alignItems='center' gap={1}>
                        <ArrowBackIcon style={{ fontSize: '2rem' }} onClick={() => navigation.goBack()} />
                        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '2rem', color: "#292524" }}>{title}</Typography>
                    </Box>
                </Box>
                <Box display='flex' flexWrap='wrap' gap={1} marginBottom={1}>
                    <Box style={{ flex: 1 }}>
                        <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                            Task Group Name
                        </Typography>
                        <TextField
                            name="group_name"
                            data-test-id="group_name"
                            value={group_name}
                            onChange={handleChange}
                            placeholder="Type group name"
                            fullWidth
                            multiline
                            InputLabelProps={{ shrink: false }}
                            style={{ marginBottom: "0.8rem" }}
                            sx={{
                                '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root': {
                                    padding: '1.1rem! important',
                                }
                            }}
                            inputProps={{
                                sx: {
                                    fontWeight: 400,
                                    fontSize: '1.6rem',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-word',
                                },
                            }}
                        />
                        {createEditErrors.group_name && (
                            <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{createEditErrors.group_name}</Typography>
                        )}
                    </Box>
                  { this.props.id=="Edit Task" &&<Box style={{ flex: 1 }}>

                        <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                            Task Group Id
                        </Typography>
                        <TextField
                            name="taskId"
                            data-test-id="taskId"
                            value={taskId}
                            fullWidth
                            disabled
                            multiline
                            InputLabelProps={{ shrink: false }}
                            style={{ marginBottom: "0.8rem" }}
                            sx={{
                                '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root': {
                                    padding: '1.1rem! important',
                                }
                            }}
                            inputProps={{
                                sx: {
                                    fontWeight: 400,
                                    fontSize: '1.6rem',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-word',
                                },
                            }}
                        />
                    </Box>}
                </Box>
                <Box display="flex" flexWrap='wrap' gap={1} marginBottom={1}>
                </Box>
                <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                    Description
                </Typography>
                <TextField
                    name="description"
                    data-test-id="description"
                    value={description}
                    onChange={handleChange}
                    multiline
                    rows={5}
                    fullWidth
                    InputLabelProps={{ shrink: false }}
                    placeholder="Type task description"
                    style={{ marginBottom: "0.8rem" }}
                    sx={{
                        '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root': {
                            padding: '1rem 0.8rem'
                        }
                    }}
                    inputProps={{
                        sx: {
                            fontWeight: 400,
                            fontSize: '1.6rem',

                        },
                    }}
                />
                                {this.renderAutoComplete("Associated Pipelines", "selectedPipelines", pipelineData, selectedPipelines)}
                                <Box sx={{ flex: 1 }}>
                        {this.renderAutoComplete("Individuals", "selectedUsers", users, selectedUsers)}
                    </Box>
                    <Box style={{ flex: 1 }}>
                        {this.renderAutoComplete("Levels", "selectedLevel", levels, selectedLevel)}
                    </Box>

                {createEditErrors.description && (
                    <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{createEditErrors.description}</Typography>
                )}
                <Box sx={{ ...createEditTextBox }}>
                    <Button sx={{
                        width: "100%",
                        padding: "1rem",
                        border: "none",
                        borderRadius: "0.8rem",
                        fontSize: "1.6rem",
                        cursor: "pointer",
                        textTransform: 'none',
                        fontWeight: 600,
                    }} data-test-id="edit-stage-btn" onClick={handleCreateEdit} disabled={!isCreateEditFormComplete()} style={{
                        backgroundColor: isCreateEditFormComplete() ? "#1A4A42" : "#A8A29E66",
                        color: isCreateEditFormComplete() ? "#fff" : "#78716C",
                        cursor: isCreateEditFormComplete() ? "pointer" : "not-allowed",
                    }}>{buttonText}</Button>
                </Box>


            </Box >
        );
    }
}

const createEditAccountGroupContainer = {
    boxSizing: "border-box",
    borderRadius: "1.2rem",
    minHeight: 'calc(100vh - 2.5rem)',
    padding: "2rem 2rem 1rem",
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
    fontFamily: "Raleway, sans-serif",
};

const createEditCommonDividerStyle = {
    margin: "1.5rem 0",
    backgroundColor: "#1A4A4233",
};

const createEditTextBox = {
    flexDirection: "column",
    backgroundColor: "#f9f9f9",
    gap: "1rem",
    padding: "1.5rem",
    display: "flex",
    borderRadius: "1.2rem",
    marginTop: "2.5rem",
    border: "0.1rem dashed #292524",
}