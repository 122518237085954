import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import {
  Box,
  IconButton,
  Button as MuiButton,
  Typography as MuiTypography,
  Divider,
  Modal,
  MenuItem,
  TextField,
  Checkbox,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import PaginationComponent from '../../cfpipelines1/src/utils/PaginationComponent';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { formatDateTimeTable } from '../../cfpipelines1/src/utils/generatePipeline';
import { TaskDataType } from "./utils/generateTasks";
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { SearchBar } from "../../../blocks/cfpipelines1/src/Cfpipelines1.web";
const icon = <CheckBoxOutlineBlankIcon style={{ height: "2rem", width: "2rem" }} />;
const checkedIcon = <CheckBoxIcon style={{ height: "2rem", width: "2rem", color: "#1A4A42", borderRadius: "20rem" }} />;
// Customizable Area End

import Cftasklistener3Controller, {
  Props,
  configJSON,
} from "./Cftasklistener3Controller";

export default class Cftasklistener3 extends Cftasklistener3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTaskColumnTypography = (flex: number, text: string | number, field: string) => {
    const { sortField, sortOrder } = this.state;
    let sortIcon = null;
    if (sortField === field) {
      sortIcon = sortOrder === 'asc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />;
    } else {
      sortIcon = <ArrowDropDownIcon />;
    }

    return (
      <MuiTypography variant="h6" data-test-id="column-typography" onClick={() => this.handleSortTasks(field)} style={{ display: "flex", alignItems: "center", fontSize: '1.4rem', flex: flex, fontWeight: 700, color: '#3E3E3E' }}>{text}{text == "Actions" ? "" : sortIcon}</MuiTypography>
    )
  }
  renderRowTypography = (flex: number, text: string | number, taskData: TaskDataType) => {
    return (
      <MuiTypography variant="h6" data-test-id="row-typography" onClick={() => this.handleTaskDetailView(taskData)} style={{
        fontSize: '1.2rem',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        flex: flex, fontWeight: 500, cursor: 'pointer', color: '#203447'
      }}>{text}</MuiTypography>
    )
  }

  renderDatePicker = (text: string, value: Dayjs, onChangeText: string, minDate: Dayjs | null = null) => {
    return (
      <Box><MuiTypography style={{
        fontSize: '1.2rem', fontWeight: 400, color: '#1A4A42', marginBottom: "0.2rem"
      }}>{text}</MuiTypography>
        <DatePicker
          value={value}
          minDate={minDate}
          format="DD-MM-YYYY"
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                placeholder="Select Date"
                sx={{
                  fontSize: '1.4rem',
                }}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    fontWeight: 400,
                    fontSize: "1.4rem",
                    borderRadius: "0.8rem",
                    '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                      padding: '0.6rem 0 0.6rem 1rem',
                      fontSize: "1.4rem",

                    }
                  },
                }}
              />
            ),
          }}
          slotProps={{
            popper: {
              sx: {
                '& .MuiPaper-root': {
                  minWidth: '30rem',
                  border: '0.1rem solid #E2E8F0',
                  marginTop: '0.2rem',
                  borderRadius: '0.8rem'
                },
                '& .MuiTypography-root': {
                  fontSize: '1.8rem !important',
                },
                '& .MuiPickersArrowSwitcher-button': {
                  fontSize: '2rem !important',
                  '& .MuiSvgIcon-root': {
                    fontSize: '2rem !important',
                  },
                },
                '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                  fontSize: '1.6rem'
                },
                '& .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon': {
                  width: '2rem',
                  height: '2rem'
                },
                '& .css-13m8yui-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': {
                  border: 'none',
                  fontWeight: 700
                },

                '& .css-1b2k5pm-MuiPickersYear-yearButton': {
                  fontSize: '1.4rem'
                },
                '& .MuiDayCalendar-weekDayLabel': {
                  fontSize: '1.4rem !important',
                  fontWeight: 400
                },
              },
            },
            day: {
              sx: {
                fontSize: '1.4rem',
              },
            },
          }}
          onChange={(newValue) => this.handleDateChange(onChangeText, newValue)}
          data-test-id="date_picker"
        />
      </Box>
    )
  }

  renderFilterDateSection = (title: string, name: string, inputState: any, onChangeText: string, inputState2: any, onChangeText2: string) => {
    const { openRows, dates } = this.state
    const minDateForToPicker = dates.createdFrom ? dayjs(dates.createdFrom).add(1, 'day') : null;
    return (
      <Box style={{ marginBottom: '0.8rem' }}>
        <Box display="flex" justifyContent='space-between' alignItems='center' flexWrap='wrap'>
          <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#3E3E3E", fontSize: '1.6rem' }}>{title}</MuiTypography>
          <IconButton data-test-id="down_up_arrow" onClick={() => this.handleRowClick(name)}>
            {openRows[name] ? <KeyboardArrowUpIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} /> : <KeyboardArrowDownIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} />}
          </IconButton>
        </Box>
        {openRows[name] &&
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                paddingTop: '0',
                display: "flex",
                gap: "1rem"
              }}
            >
              {this.renderDatePicker("From", inputState, onChangeText)}
              {this.renderDatePicker("To", inputState2, onChangeText2, minDateForToPicker)}
            </Box>
          </LocalizationProvider>
        }
      </Box>
    )
  }
  renderCheckBox = (name: string, text: string, checkValue: boolean) => {

    return (
      <Box
        display="flex"
        alignItems="center"
        sx={{
          border: "0.1rem solid #1A4A4233",
          borderRadius: "0.8rem",
          boxSizing: "border-box",
          flex: "1 1 calc(50% - 1rem)"

        }}
      >
        <Checkbox
          checked={checkValue}
          onChange={this.handleLevelChange}
          icon={icon}
          checkedIcon={checkedIcon}
          name={name}
        />
        <MuiTypography
          sx={{
            fontSize: "1.4rem",
            fontWeight: 400,
            color: "#0F172A"
          }}
        >
          {text}
        </MuiTypography>
      </Box>
    );
  }
  renderPermissionFilterSection = (title: string, name: string) => {
    const { openRows } = this.state
    return (
      <Box><Box display="flex" justifyContent='space-between' alignItems='center' flexWrap='wrap'>
        <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#3E3E3E", fontSize: '1.6rem' }}>{title}</MuiTypography>
        <IconButton data-test-id="down_up_arrow" onClick={() => this.handleRowClick(name)}>
          {openRows[name] ? <KeyboardArrowUpIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} /> : <KeyboardArrowDownIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} />}
        </IconButton>
      </Box>
        {openRows[name] &&
          <Box
            display="flex"
            sx={{ gap: "1rem", flexWrap: 'wrap' }}
          >
            {this.renderCheckBox("level1", "Level 1", this.state.levels.level1)}
            {this.renderCheckBox("level2", "Level 2", this.state.levels.level2)}
            {this.renderCheckBox("level3", "Level 3", this.state.levels.level3)}
            {this.renderCheckBox("level4", "Level 4", this.state.levels.level4)}
          </Box>
        }
      </Box>
    )
  }

  renderTasksComponent = (Children: (pppp:TaskDataType[])=>React.ReactNode) => {
    const { currentPageIndex, requiredPageSize } = this.state
    const sortedRows = this.handleSortTaskData()
    const paginatedRows = sortedRows.slice(currentPageIndex * requiredPageSize, (currentPageIndex + 1) * requiredPageSize);
    const startIndex = currentPageIndex * requiredPageSize + 1;
    const endIndex = Math.min((currentPageIndex + 1) * requiredPageSize, sortedRows.length);
    const totalResults = sortedRows.length;

    return (
      <Box sx={{ ...accountGroupContainer }}>
        <Box style={{ display: "flex", marginBottom: '1.6rem', justifyContent: "space-between", alignItems: 'center', flexWrap: 'nowrap' }}>
          <MuiTypography variant="h6" sx={{ fontWeight: 700, fontSize: '2rem', color: "#292524" }}>
            Task Groups
          </MuiTypography>

          <Box display='flex' alignItems='center' gap={1}>
          <SearchBar handleSearchField={this.handleSearchTaskGroup} />
            <MuiButton onClick={this.handleTaskCreate} sx={{
              backgroundColor: '#1A4A42',
              color: 'white',
              padding: '0.4rem 1.6rem',
              fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1.4rem',
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: '#1B5C54',
                color: '#fff',
              }
            }}>
              Create Task Group
            </MuiButton>
            <Box style={{ position: 'relative' }}>
              <FilterListIcon onClick={this.toggleFilterModal} style={{ ...iconStyle, cursor: "pointer", fontSize: "2rem" }} />
              {this.state.isFilterModal &&
                <Box
                  sx={filterModalStyle}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#3E3E3E", fontSize: '1.6rem' }}>Filters</MuiTypography>
                    <IconButton onClick={() => this.toggleFilterModal()} >
                      <CloseIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} />
                    </IconButton>
                  </Box>
                  {this.renderFilterDateSection("Date Created", "created_at", this.state.dates.createdFrom, "createdFrom", this.state.dates.createdTo, "createdTo")}
                  {this.renderFilterDateSection("Last Edited", "updated_at", this.state.dates.editedFrom, "editedFrom", this.state.dates.editedTo, "editedTo")}
                  {this.renderPermissionFilterSection("Permission Level", "permission_level")}
                  <Box display="flex" justifyContent="flex-end" gap={1} mt={2}>
                    <MuiButton onClick={this.resetFilters} variant="contained" sx={{
                      backgroundColor: '#1A4A421A',
                      color: '#1A4A4299',
                      padding: '0.8rem',
                      fontWeight: 700,
                      borderRadius: '0.8rem',
                      textTransform: 'none',
                      fontSize: '1.2rem',
                      width: '8rem',
                      '&:hover': {
                        backgroundColor: '#F5FEFD',
                      }
                    }}>
                      Reset
                    </MuiButton>
                    <MuiButton variant="contained" onClick={this.handleApplyTaskFilter} sx={{
                      backgroundColor: '#1A4A42',
                      color: 'white',
                      padding: '0.8rem',
                      fontWeight: 700,
                      borderRadius: '0.8rem',
                      textTransform: 'none',
                      fontSize: '1.2rem',
                      width: '8rem',
                      '&:hover': {
                        backgroundColor: '#1B5C54',
                      }
                    }}>
                      Apply
                    </MuiButton>
                  </Box>
                </Box>
              }
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection='column' gap={1}>
          <Box display="flex" style={{ backgroundColor: '#29252414', padding: '0.5rem 1rem', borderRadius: ' 0.8rem' }}>
            {this.renderTaskColumnTypography(3, 'Task Group ID', 'id')}
            {this.renderTaskColumnTypography(3.2, 'Name', 'name')}
            {this.renderTaskColumnTypography(3, 'Date Created', 'created_at')}
            {this.renderTaskColumnTypography(3, 'Last Edited', 'updated_at')}
            {this.renderTaskColumnTypography(6.5, 'Members', 'asso_pipelines')}
            {this.renderTaskColumnTypography(1.5, 'Actions', 'actions')}

          </Box>
          {Children(paginatedRows)}

        </Box>
        <PaginationComponent currentPageIndex={currentPageIndex} requiredPageSize={requiredPageSize} startIndex={startIndex} endIndex={endIndex} totalResults={totalResults} handlePageChange={this.handlePageChangeTask} />

      </Box>
    )
  }
  renderTaskList = (paginatedRows: TaskDataType[]) => {
    return <>
      {paginatedRows.length > 0 ? paginatedRows.map(row => (
        <Box key={row.task_id} style={{ padding: '0.5rem 1rem', borderBottom: '0.1rem solid #1A4A421A', alignItems: 'center' }}>
          <Box display='flex' style={{ alignItems: 'center' }}>
            {this.renderRowTypography(3, `${row.task_id}`, row)}
            {this.renderRowTypography(3.2, row.task_name, row)}
            {this.renderRowTypography(3, formatDateTimeTable(row.created_at), row)}
            {this.renderRowTypography(3, formatDateTimeTable(row.updated_at), row)}
            <Box  marginTop={1} marginBottom={1} sx={{flex:6.5}} display='flex' flexDirection='row' gap={1}>
                <Box display='flex' gap={1} alignItems='center'>
                  {row.levels.map((u) => (
                    <Box key={u.label} display='inline-flex' gap={1} justifyContent={"center"} alignItems='center'
                      sx={{
                        border: '0.1rem solid #1A4A4266',
                        backgroundColor: "white",
                        padding:"0.4rem",
                        borderRadius: "0.7rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        color: "#203447",
                        maxWidth: '2.4rem',
                        minWidth: '2.4rem',
                        boxSizing: 'border-box'
                      }}
                    >
                      <MuiTypography style={{ fontSize: '1rem', fontWeight: 800 }}>
                        {u.label.charAt(0).toUpperCase() + u.label.slice(5)}
                      </MuiTypography>
                    </Box>
                  ))}
                </Box>
                <Box display='flex' gap={1} alignItems='center'>
                  {row.individualUser.slice(0, 4).map((u,index) => (
                    <Box key={u.user_name} display='inline-flex' alignItems='center'  sx={{}}>
                      <img
                        src={u.source}
                        alt='i,mm'
                        style={{
                          borderRadius: '50%',
                          height: '2.4rem',
                          width: '2.4rem',
                          objectFit: 'cover',
                          marginRight:-19,
                          zIndex:10,borderColor: "white",
                          border: '2px solid white'
                        }}
                      />
                      
                    </Box>
                  ))}
                  <Box sx={{marginRight:-19, background:"#C1C7D0", zIndex:10, border: '0.1rem solid #C1C7D0', borderRadius: '50%', height: '2.4rem', width: '2.4rem', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                    <MuiTypography style={{
                      fontSize: '1.1rem', fontWeight: 600, color: "#fff"
                    }}>
                      +{row.individualUser.length - 4}
                    </MuiTypography>
                  </Box>
                </Box>
              
            </Box>
            <Box style={{flex:1.5}}>
              <IconButton data-test-id="duplicate-btn-test" sx={addButtonStyle} style={{ padding: "0.6rem" }} onClick={() =>{}}>
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
            </Box>

          </Box>
        </Box>
      )) : <Box display='flex' justifyContent='center' alignItems='center'><MuiTypography variant="h5">No tasks available. Create a new one.</MuiTypography></Box>}</>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.renderTasksComponent(this.renderTaskList)}
        <Modal
          open={this.state.isDeleteTask}
          onClose={this.toggleTaskDeleteModal}
          aria-labelledby="delete-stage-modal"
          aria-describedby="delete-stage-form"
        >
          <Box sx={tasksModalStyle}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '2rem' }}>Delete Task</MuiTypography>
              <IconButton onClick={this.toggleTaskDeleteModal}>
                <CloseIcon style={{ height: "2rem", width: '2rem' }} />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem", padding: 0, width: '100%' }} />
            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
              <MuiTypography variant="h6" style={{ fontSize: '1.2rem' }}>{`Are you sure want to delete stage: ${this.state.taskId} ?`}</MuiTypography>
            </Box>
            <Box display="flex" justifyContent="center" gap={2} mt={3}>
              <MuiButton onClick={this.toggleTaskDeleteModal} variant="contained" sx={{
                backgroundColor: '#1A4A421A',
                color: '#1A4A4299',
                padding: '1rem 0.8rem',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                fontSize: '1.2rem',
                width: '10rem',
                '&:hover': {
                  backgroundColor: '#F5FEFD',
                }
              }}>
                Cancel
              </MuiButton>
              <MuiButton onClick={this.handleDeleteSingleTask} variant="contained" sx={{
                backgroundColor: '#1A4A42',
                color: 'white',
                padding: '1rem 0.8rem',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                fontSize: '1.2rem',
                width: '10rem',
                '&:hover': {
                  backgroundColor: '#1B5C54',
                }
              }}>
                Delete
              </MuiButton>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.isAddUserModalOpen}
          onClose={this.toggleUserModal}
          aria-labelledby="add-user-modal"
          aria-describedby="add-user-form"
        >
          <Box sx={tasksModalStyle}>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524" }}>Add New User</MuiTypography>
              <IconButton onClick={this.toggleUserModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
            <Box component="form" noValidate autoComplete="off" onSubmit={this.handleAddUser}>
              <MuiTypography variant="body1" style={{ margin: "0.6rem 0 1.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.2rem' }}>
                Task Name: <span style={{ color: "#292524", fontWeight: 600 }}>{this.state.task_name}</span>
              </MuiTypography>
              <MuiTypography variant="body1" style={{ margin: "0.5rem 0", color: "#475569", fontWeight: 700, fontSize: "1.2rem" }}>
                Select User
              </MuiTypography>
              <TextField
                name="singleSelectedUser"
                data-test-id="singleSelectedUser"
                value={this.state.selectedUser.user_name || ''}
                sx={{ marginBottom: '0.8rem' }}
                select
                fullWidth
                inputProps={{
                  sx: {
                    padding: '1rem 0.8rem',
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    borderRadius: '0.8rem',
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
                InputLabelProps={{ shrink: false }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: () => {
                    if (!this.state.selectedUser.user_name) {
                      return <span style={{ color: "#999" }}>Search & Select User</span>;
                    }
                    return (
                      <Box display="flex" alignItems="center" gap={1}>
                        <img
                          src={this.state.selectedUser.source}
                          alt={this.state.selectedUser.user_name}
                          style={{ width: '24px', height: '24px', borderRadius: '50%', objectFit: 'cover' }}
                        />
                        {this.state.selectedUser.user_name}
                      </Box>
                    );
                  },
                }}
              >
                {this.state.userMembers.map((user, index) => (
                  <MenuItem
                    key={index}
                    value={user.user_name}
                    sx={{
                      fontSize: '1.6rem',
                      fontWeight: 400,
                      backgroundColor: this.state.selectedUser.user_name === user.user_name ? '#1A4A421A' : 'white',
                      '&:hover': {
                        backgroundColor: this.state.selectedUser.user_name === user.user_name ? '#1A4A421A' : '#f0f0f0',
                      },
                    }}
                    onClick={() => this.handleUserSelect(user)}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <img
                        src={user.source}
                        alt={user.user_name}
                        style={{ width: '24px', height: '24px', borderRadius: '50%', objectFit: 'cover' }}
                      />
                      {user.user_name}
                    </Box>
                  </MenuItem>
                ))}
              </TextField>

              <Box display="flex" justifyContent="center" gap={2} mt={3}>
                <MuiButton onClick={this.toggleUserModal} variant="contained" sx={{
                  backgroundColor: '#1A4A421A',
                  color: '#1A4A4299',
                  padding: '0.8rem 0',
                  fontWeight: 700,
                  borderRadius: '0.8rem',
                  textTransform: 'none',
                  width: "12rem"
                }}>
                  Cancel
                </MuiButton>
                <MuiButton type="submit" variant="contained" sx={{
                  backgroundColor: '#1A4A42',
                  color: 'white',
                  padding: '0.8rem 0',
                  fontWeight: 700,
                  borderRadius: '0.8rem',
                  textTransform: 'none',
                  width: "12rem"
                }}>
                  Save
                </MuiButton>
              </Box>
            </Box>
          </Box>
        </Modal>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const accountGroupContainer = {
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  fontFamily: "Raleway, sans-serif",
  boxSizing: "border-box",
};

const dividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};
const iconStyle = {
  backgroundColor: "#1A4A424D",
  color: "#064E3B",
  padding: "0.6rem",
  borderRadius: "0.6rem",
};
const addButtonStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "1rem",
  borderRadius: "0.8rem",
};
const deleteIconStyle = {
  backgroundColor: "#EF444433",
  color: "#DC2626",
  padding: "0.45rem",
  borderRadius: "0.6rem",
};
const tasksModalStyle = {
  position: 'absolute',
  borderRadius: '0.8rem',
  boxShadow: 2,
  transform: 'translate(-50%, -50%)',
  width: '40rem',
  top: '50%',
  left: '50%',
  bgcolor: 'background.paper',
  p: 3,
};
const filterModalStyle = {
  position: 'absolute',
  top: '100%',
  right: '10%',
  width: '28rem',
  bgcolor: 'background.paper',
  borderRadius: '0.8rem',
  boxShadow: 2,
  zIndex: '99',
  p: 2,
}
// Customizable Area End
