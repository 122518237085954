import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface PipelineCreateErrorType {
  pipeline_name?: string,
  description?: string,
}
export interface TaskType {
  id:string
  type:string
  attributes: {
    id:number
    task_name: string
  label: string
  }
}
export interface LevelType {
  id:string
  type:string
  attributes: {
    id:number
  permission_level: string
  label: string}
}
export interface UserType {
  id:string
  type:string
  attributes: {
    id:number
  user_name: string
  label: string,
  source: string
  }
}
import { toast } from "react-toastify"
import { generatePipeline, generateRandomUsers } from "./utils/generatePipeline";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pipeline_name: string;
  description: string;
  createPipelineErrors: PipelineCreateErrorType
  selectedTasks: TaskType[]
  createTasksData: any[]
  associatedClinic: string
  createLevelsData: LevelType[]
  createUsersData: UserType[]
  selectedUsers: UserType[]
  selectedLevels: LevelType[]
  isLoading:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreatePipelineController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createPipelineAPICallId: string="";
  levelsListCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.levelsListAPI()
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pipeline_name: '',
      description: '',
      createPipelineErrors: {},
      selectedTasks: [],
      selectedUsers: [],
      selectedLevels: [],
      createTasksData:  [
        {
            "id": "2",
            "type": "permission",
            "attributes": {
                "id": 2,
                "label": "task 1",
                "task_name": "task 1"            
              }
        },
        {
            "id": "3",
            "type": "task",
            "attributes": {
                "id": 3,
                "label": "task 2",
                "task_name": "task 2"
            }
        },
        {
            "id": "4",
            "type": "task",
            "attributes": {
                "id": 4,
                "label": "task 3",
                "task_name": "task 3"
            }
        }
    ],
      createLevelsData: [],
      createUsersData:[
        {
            "id": "1",
            "type": "user",
            "attributes": {
                "id": 1,
                "label": "John Smith",
                "user_name": "John Smith",
                "source": "https://randomuser.me/api/portraits/men/1.jpg"
            }
        },
        {
            "id": "2",
            "type": "user",
            "attributes": {
                "id": 2,
                "label": "Michael Johnson",
                "user_name": "Michael Johnson",
                "source": "https://randomuser.me/api/portraits/men/2.jpg"
            }
        },
        {
            "id": "3",
            "type": "user",
            "attributes": {
                "id": 3,
                "label": "James Brown",
                "user_name": "James Brown",
                "source": "https://randomuser.me/api/portraits/men/3.jpg"
            }
        },
        {
            "id": "4",
            "type": "user",
            "attributes": {
                "id": 4,
                "label": "Robert Williams",
                "user_name": "Robert Williams",
                "source": "https://randomuser.me/api/portraits/men/4.jpg"
            }
        },
        {
            "id": "5",
            "type": "user",
            "attributes": {
                "id": 5,
                "label": "David Jones",
                "user_name": "David Jones",
                "source": "https://randomuser.me/api/portraits/men/5.jpg"
            }
        },
        {
            "id": "6",
            "type": "user",
            "attributes": {
                "id": 6,
                "label": "Mary Miller",
                "user_name": "Mary Miller",
                "source": "https://randomuser.me/api/portraits/women/1.jpg"
            }
        },
        {
            "id": "7",
            "type": "user",
            "attributes": {
                "id": 7,
                "label": "Jennifer Davis",
                "user_name": "Jennifer Davis",
                "source": "https://randomuser.me/api/portraits/women/2.jpg"
            }
        },
        {
            "id": "8",
            "type": "user",
            "attributes": {
                "id": 8,
                "label": "Patricia Garcia",
                "user_name": "Patricia Garcia",
                "source": "https://randomuser.me/api/portraits/women/3.jpg"
            }
        },
        {
            "id": "9",
            "type": "user",
            "attributes": {
                "id": 9,
                "label": "Linda Martinez",
                "user_name": "Linda Martinez",
                "source": "https://randomuser.me/api/portraits/women/4.jpg"
            }
        }
    ]
    ,
      associatedClinic: '',
      isLoading:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
     let errorResponse  =message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )
      if (apiRequestCallId) {
        switch (apiRequestCallId) {
          case this.createPipelineAPICallId:
            this.handleCreatePipelineRes(responseJson, errorResponse);
            break;
          case this.levelsListCallId:
            this.handleLevelsListRes(responseJson,errorResponse)
          
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleCreatePipelineRes=(responseJson:any,errorRes: string)=>{
    if (!responseJson.error) {
         toast.success("Pipeline created successfully", { style: { fontSize: "1.6rem" } })
    this.props.navigation.navigate("PipelineDetail", { pipelineId:responseJson.id })

    }
  }
  handleLevelsListRes=(responseJson:any,errorResponse:any)=>{
    if(responseJson.data){
      this.setState({createLevelsData:responseJson.data,isLoading:false})
    }
  }

  levelsListAPI=async ()=>{
    const token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.levelsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.levelsListApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  isCreatePipelineFormComplete = () => {
    const { pipeline_name, description, selectedLevels, selectedTasks, selectedUsers, associatedClinic } = this.state;
    return (
      pipeline_name &&
      description &&
      associatedClinic && selectedLevels.length > 0 && selectedUsers.length > 0 && selectedTasks.length > 0
    );
  };

  async componentDidMount() {
    super.componentDidMount()
  }

  handleCreateChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      const updatedErrors = { ...prevState.createPipelineErrors };

      if (name === "pipeline_name" && value.length <= 100) {
        delete updatedErrors.pipeline_name;
      }

      if (name === "description" && value.length <= 300) {
        delete updatedErrors.description;
      }

      return {
        ...prevState,
        [name]: value,
        createPipelineErrors: updatedErrors,
      };
    });
  };
  validateCreatePipelineFields = (pipeline_name: string, description: string) => {
    const errors: PipelineCreateErrorType = {};

    if (pipeline_name.length > 100) {
      errors.pipeline_name = "Pipeline name cannot be more than 100 characters.";
    }

    if (description.length > 300) {
      errors.description = "Pipeline description cannot exceed 300 characters.";
    }

    return errors;
  };

  handleCreatePipeline = async (event: any) => {
    event.preventDefault()
    const { pipeline_name, description, associatedClinic, selectedLevels, selectedTasks, selectedUsers } = this.state
    const pipelineErrors = this.validateCreatePipelineFields(pipeline_name, description);
    if (Object.keys(pipelineErrors).length > 0) {
      this.setState({ createPipelineErrors: pipelineErrors });
      return;
    }
    let selectedLevelIds=selectedLevels.map((item) => item.attributes.id);
    const token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const httpBody = {
        "pipeline": {
            "pipeline_name":pipeline_name ,
            "description": description,
            "status": "to_do",
            "permission_ids": selectedLevelIds
        }
    } 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createPipelineAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPipelineApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleChangeCreateAutoComplete = (name: string) => (event: any, newValue: any) => {
    this.setState((prev) => {
      return {
        ...prev,
        [name]: newValue
      }
    });
  }
  handleCreateClinicSelect = (clinic: string) => {
    this.setState({ associatedClinic: clinic })
  }
  // Customizable Area End
}
