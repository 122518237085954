// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface TriggerData {
id:number,
trigger_name:string,
selection1:string,
value1:string,
selection2:string,
value2:string,
created_at:string,
updated_at:string
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentPage: number,
  pageSize: number,
  rowData: TriggerData[],
  filteredData: TriggerData[],
  searchQuery: string
  isLoading:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TriggersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    triggerListCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentPage: 0,
      pageSize: 10,
      rowData: [],
      filteredData: [],
      searchQuery: '',
      isLoading:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
     let errorResponse  =message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )
      if (apiRequestCallId===this.triggerListCallId) {
            this.handleTriggerListRes(responseJson, errorResponse);
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    this.triggerListAPI()
  }

  handleTriggerListRes=(responseJson:TriggerData[],errorRes: string)=>{
    if (responseJson) {
      this.setState({isLoading:false,filteredData: responseJson, rowData: responseJson })
    }
  }

  triggerListAPI=async ()=>{
    const token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.triggerListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.triggerListApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handlePageChange = (event: any, value: number) => {
    this.setState({ currentPage: value - 1 });
  };

  handleTriggerNavigate = (trigger: TriggerData) => {
    if (this.props.navigation.navigate) {
      this.props.navigation.navigate('TriggerDetail', { trigger: trigger.id })
    }
  }

  handleSearchTrigger = (query: string) => {
    this.setState({ searchQuery: query });
    const { rowData } = this.state
    if (query === '') {
      this.setState({ filteredData: rowData });
    }
    else {
      const filtered = rowData.filter(trigger =>
        trigger.trigger_name?.toLowerCase().includes(query.toLowerCase()) 
        // ||
        // pipeline.id.toString()?.includes(query.toUpperCase()) ||
        // pipeline.clinic_name?.toLowerCase().includes(query.toLowerCase())
      );

      this.setState({ filteredData: filtered });
    }
  };

  // Customizable Area End
}
