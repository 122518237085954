import React from "react";
// Customizable Area Start
import { styled } from "@mui/styles";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import TopNav from "../../navigationmenu/src/TopNav.web";
import  {
  configJSON,
} from "./LandingPageController";
// Customizable Area End

import LandinPageController, {
  Props,
} from "./LandingPageController";

export default class LandingPage extends LandinPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const arr = new Array(8).fill(0)
    return (
      // Customizable Area Start
      <MainContainer>
        <TopNav navigation={this.props.navigation} id="Landing Page" />
        <Divider />
        <EventContainer>
          <EventButton style={{ fontSize: '1.4rem' }}>{configJSON.eventHeading}</EventButton>
          <EventButton style={{ fontSize: '1.4rem' }}>Triggers</EventButton>
          <EventButton style={{ fontSize: '1.4rem' }} onClick={this.handleCreate}>Create Pipeline</EventButton>
        </EventContainer>
        <CardContainer>
          <Card data-test-id="permission-card" onClick={() => this.props.navigation.navigate('Cftasklistener3')}>
            <div>
              <CardHeader style={{ fontSize: '1.6rem' }}>Permissions</CardHeader>
              <CardBody style={{ fontSize: '1.4rem' }}>
                Permissions created on Total Life CRM
              </CardBody>
            </div>
            <CardFooter>
              <p style={{ fontWeight: "700", position: 'relative', top: '2rem', fontSize: '2rem' }}><span style={{ fontSize: '4.6rem', color: "#E46760", fontWeight: "500", marginRight: '0.2rem', fontFamily: "Outfit" }}>18</span> Permissions</p>
            </CardFooter>
          </Card>
          <Card onClick={() => this.props.navigation.navigate('Cfpipelines1')} data-test-id="pipeline-card">
            <div>
              <CardHeader style={{ fontSize: '1.6rem' }}>Pipelines</CardHeader>
              <CardBody style={{ fontSize: '1.4rem' }}>
                Pipelines created on Total Life CRM
              </CardBody>
            </div>
            <CardFooter>
              <p style={{ fontWeight: "700", position: 'relative', top: '2rem', fontSize: '2rem' }}><span style={{ fontSize: '4.6rem', color: "#E46760", fontWeight: "500", marginRight: "0.2rem", fontFamily: "Outfit" }}>{this.state.pipelineData.length}</span> Pipelines</p>
            </CardFooter>
          </Card>
        </CardContainer>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "2rem", boxShadow: "0.2rem 0.4rem 2rem 0 rgba(0, 0, 0, 0.06)", padding: "2rem 0" }}>
          <SectionContainer>
            <SectionHeader>
              <p style={{ fontWeight: "700", fontSize: '1.6rem' }}>Tasks List</p>
              <StyledButton style={{ fontSize: '1.2rem' }}>View All</StyledButton>
            </SectionHeader>
            <ListHeader style={{ fontSize: '1.4rem' }}>
              <p>Name</p>
              <p style={{ marginRight: "1.2rem" }}>Actions</p>
            </ListHeader>
            {arr.map((_, index) => (
              <ListItem key={index}>
                <p style={{ fontWeight: "700", fontSize: '1.4rem' }}>Task {index + 1}</p>
                <IconActions>
                  <EditIcon style={{ ...iconStyle, fontSize: '2rem' }} />
                  <DeleteOutlineIcon style={{ ...deleteIconStyle, fontSize: '2rem' }} />
                </IconActions>
              </ListItem>
            ))}
          </SectionContainer>

          <SectionContainer>
            <SectionHeader>
              <p style={{ fontWeight: "700", fontSize: '1.6rem' }}>Pipelines</p>
              <StyledButton data-test-id="pipeline-list" style={{ fontSize: '1.2rem' }} type="button" onClick={() => this.props.navigation.navigate('Cfpipelines1')}>View All</StyledButton>
            </SectionHeader>
            <ListHeader style={{ fontSize: '1.4rem' }}>
              <p>Name</p>
              <p style={{ marginRight: "1.2rem" }}>Actions</p>
            </ListHeader>
            {this.state.pipelineData.slice(0,8).map((data: any, index: number) => (
              <ListItem key={index}>
                <p style={{ fontWeight: "700", fontSize: '1.4rem' }}>{data.pipeline_name}</p>
                <IconActions>
                  <StyledButton style={{ fontSize: '1.2rem' }}>View Rules</StyledButton>
                  <EditIcon style={{ ...iconStyle, fontSize: '2rem' }} />
                  <DeleteOutlineIcon style={{ ...deleteIconStyle, fontSize: '2rem' }} />
                </IconActions>
              </ListItem>
            ))}
          </SectionContainer>
        </div>
      </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled("div")({
  padding: "2rem",
  justifyContent: "center",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  maxWidth: "100%",
  boxSizing: "border-box",
  overflowX: "hidden",
});

const EventContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  flexWrap: "wrap",
  gap: "0.5rem",
  marginBottom: "1.5rem",
})
const EventButton = styled("button")({
  backgroundColor: "#1A4A42",
  color: "#fff",
  padding: "0.8rem 1.6rem",
  border: "none",
  borderRadius: "0.8rem",
  cursor: "pointer",
  width: "auto",
  fontWeight: "700",
  height: "100%",
});
const CardContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  maxWidth: "100%",
  gap: "2rem",
  "@media (max-width: 427px)": {
    flexDirection: "column",
    gap: "0.8rem",
  }
});

const Card = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "0.8rem",
  boxShadow: "rgba(99, 99, 99, 0.5) 0 0.2rem 0.6rem 0",
  padding: "1.5rem",
  flex: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  maxWidth: "100%"
});

const CardFooter = styled("div")({
  fontSize: "10.8rem",
  fontWeight: "700",
  marginTop: "0.4rem",
  position: "relative",
  alignSelf: 'flex-end'
});

const CardHeader = styled("div")({
  margin: "0.5rem 0",
  fontSize: "1.6rem",
  fontWeight: "700",
});

const CardBody = styled("div")({
  fontSize: "10.4rem",
});

const SectionContainer = styled("div")({
  flex: 1,
  backgroundColor: "#fff",
  borderRadius: "0.8rem",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
})

const SectionHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 2rem",
});

const StyledButton = styled("button")({
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "0.8rem 1.6rem",
  border: "none",
  borderRadius: "0.8rem",
  cursor: "pointer",
  width: "auto",
  height: "100%",
  fontWeight: "700"
});

const ListHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 2rem",
  backgroundColor: "#1A4A421A",
  fontWeight: "700",
});

const ListItem = styled("div")({
  display: "flex",
  justifyContent: 'space-between',
  alignItems: "center",
  padding: "0 2rem",
});

const IconActions = styled("div")({
  display: "flex",
  gap: "0.8rem",
  alignItems: "center"
});

const iconStyle = {
  backgroundColor: "#1A4A424D",
  color: "#064E3B",
  padding: "0.45rem",
  borderRadius: "0.6rem",
};

const deleteIconStyle = {
  backgroundColor: "#EF444433",
  color: "#DC2626",
  padding: "0.45rem",
  borderRadius: "0.6rem",
};

const Divider = styled("hr")({
  width: "100%",
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
  color:"#1A4A4233"
});

// Customizable Area End
