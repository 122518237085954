import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface PipelineEditErrorType {
    pipeline_name?: string,
    description?: string,
}
import { toast } from "react-toastify"
import { generateRandomUsers, PipelineType } from "./utils/generatePipeline";
import { LevelType, TaskType, UserType } from "./CreatePipelineController";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    pipeline_name: string;
    description: string;
    editPipelineErrors: PipelineEditErrorType
    selectedTasks: TaskType[]
    editTasksData: TaskType[]
    associatedClinic: string
    editLevelsData: LevelType[]
    editUsersData: UserType[]
    selectedUsers: UserType[]
    selectedLevels: LevelType[]
    singlePipeline: PipelineType
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EditpipelineController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            pipeline_name: '',
            description: '',
            editPipelineErrors: {},
            selectedTasks: [],
            selectedUsers: [],
            selectedLevels: [],
            editTasksData: [
                {
                    "id": "2",
                    "type": "permission",
                    "attributes": {
                        "id": 2,
                        "label": "task 1",
                        "task_name": "task 1"            
                      }
                },
                {
                    "id": "3",
                    "type": "task",
                    "attributes": {
                        "id": 3,
                        "label": "task 2",
                        "task_name": "task 2"
                    }
                },
                {
                    "id": "4",
                    "type": "task",
                    "attributes": {
                        "id": 4,
                        "label": "task 3",
                        "task_name": "task 3"
                    }
                }
            ],
            editLevelsData: [
                {
                    "id": "2",
                    "type": "permission",
                    "attributes": {
                        "id": 2,
                        "label": "Level 1",
                        "permission_level": "level1"
                    }
                },
                {
                    "id": "3",
                    "type": "permission",
                    "attributes": {
                        "id": 3,
                        "label": "Level 2",
                        "permission_level": "level2"
                    }
                },
                {
                    "id": "4",
                    "type": "permission",
                    "attributes": {
                        "id": 4,
                        "label": "Level 3",
                        "permission_level": "level3"
                    }
                }
            ],
            editUsersData: [],
            associatedClinic: '',
            singlePipeline: {
                id: '', pipeline_name: '',
                description: '',
                created_at: '',
                updated_at: '',
                permission_level: '',
                pipeline_serial_id: '',
                status: '',
                order: null,
                stages: [],
                clinic_name: '',
                visibleBy: { IndividualUsers: [], PermissionLevels: [], TaskGroups: [] },
                rules: []
            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    isEditPipelineFormComplete = () => {
        const { pipeline_name, description, selectedLevels, selectedTasks, selectedUsers, associatedClinic } = this.state;
        return (
            pipeline_name &&
            description &&
            associatedClinic && selectedLevels.length > 0 && selectedUsers.length > 0 && selectedTasks.length > 0
        );
    };

    async componentDidMount() {
        super.componentDidMount()
    }

    handleEditChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        this.setState((prevState) => {
            const updatedErrors = { ...prevState.editPipelineErrors };

            if (name === "pipeline_name" && value.length <= 100) {
                delete updatedErrors.pipeline_name;
            }

            if (name === "description" && value.length <= 300) {
                delete updatedErrors.description;
            }

            return {
                ...prevState,
                [name]: value,
                editPipelineErrors: updatedErrors,
            };
        });
    };
    validateEditPipelineFields = (pipeline_name: string, description: string) => {
        const errors: PipelineEditErrorType = {};

        if (pipeline_name.length > 100) {
            errors.pipeline_name = "Pipeline name cannot be more than 100 characters.";
        }

        if (description.length > 300) {
            errors.description = "Pipeline description cannot exceed 300 characters.";
        }

        return errors;
    };

    handleEditPipeline = (event: any) => {
        event.preventDefault()
        const { pipeline_name, description, associatedClinic, singlePipeline, selectedLevels, selectedTasks, selectedUsers } = this.state
        const pipelineErrors = this.validateEditPipelineFields(pipeline_name, description);

        if (Object.keys(pipelineErrors).length > 0) {
            this.setState({ editPipelineErrors: pipelineErrors });
            return;
        }
    }

    handleChangeEditAutoComplete = (name: string) => (event: any, newValue: any) => {
        this.setState((prev) => {
            return {
                ...prev,
                [name]: newValue
            }
        });
    }
    handleEditClinicSelect = (clinic: string) => {
        this.setState({ associatedClinic: clinic })
    }
    // Customizable Area End
}
