import React from "react";
// Customizable Area Start
import Custompipelinecomp from "./utils/Custompipelinecomp.web";
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from "@mui/material";
// Customizable Area End

import CreatePipelineController, {
    Props,
} from "./CreatePipelineController";

export default class CreatePipeline extends CreatePipelineController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { isLoading, description, pipeline_name, createLevelsData, createPipelineErrors, createTasksData, createUsersData, selectedLevels, selectedTasks, selectedUsers, associatedClinic } = this.state
        return (
            <> {
                isLoading ?
                <LoadingSpinner />
                :
                    <Custompipelinecomp
                        navigation={this.props.navigation}
                        id="Createpipeline"
                        description={description}
                        handleChangeValue={this.handleCreateChangeValue}
                        pipeline_name={pipeline_name}
                        handleCreateEditPipeline={this.handleCreatePipeline}
                        title="Create Pipeline"
                        handleAutoComplete={this.handleChangeCreateAutoComplete}
                        allLevels={createLevelsData}
                        allUsers={createUsersData}
                        allTasks={createTasksData}
                        customSelectedLevels={selectedLevels}
                        customSelectedUsers={selectedUsers}
                        customSelectedTasks={selectedTasks}
                        customAssociatedClinic={associatedClinic}
                        handleCustomSelectClinic={this.handleCreateClinicSelect}
                        isCustomFormComplete={this.isCreatePipelineFormComplete}
                        buttonText="Create Pipeline"
                        customPipelinesError={createPipelineErrors}
                    />}            </>

        );
        // Customizable Area End
    }
}

// Customizable Area Start
export const LoadingSpinner = ({ size = 25, color = "#1A4A42E5" }) => (
    <Box
        sx={{
            minHeight: 'calc(100vh - 2.5rem)',
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}
    >
        <CircularProgress size={size} style={{ color }} />
    </Box>
);

// Customizable Area End
