import React from 'react';

// Customizable Area Start
import { Box, Button, Typography, Divider, Modal, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { formatDateTimeField } from '../../cfpipelines1/src/utils/generatePipeline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
// Customizable Area End

import DetailtaskgroupController, {
  Props,
} from './DetailtaskgroupController';

export default class Detailtaskgroup extends DetailtaskgroupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { singleTask, pipelinesData, levelsData, isDeleteTask } = this.state
    return (
      <Box sx={{ ...detailAccountGroupContainer }}>
        <Box display="flex" justifyContent='space-between' marginBottom={2}>
          <Box display='flex' alignItems='center' gap={1}>
            <ArrowBackIcon data-test-id="arrow_back" style={{ fontSize: '2rem' }} onClick={() => this.props.navigation.goBack()} />
            <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '2.2rem', color: "#292524" }}>{singleTask.task_name}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Button data-test-id="edit-task-btn" onClick={this.handleEditNavigate} variant="contained" sx={{
              backgroundColor: '#1A4A42',
              color: '#FFF',
              fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1.2rem',
              whiteSpace: 'nowrap',
              boxShadow: 'none',
              width: '8rem',
              '&:hover': {
                backgroundColor: '#1B5C54',
              }
            }}>
              Edit
            </Button>
            <Button data-test-id="delete-task-btn" onClick={this.toggleDeleteTask} variant="contained" sx={{
              backgroundColor: '#EF444433',
              color: '#DC2626',
              fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1.2rem',
              whiteSpace: 'nowrap',
              boxShadow: 'none',
              width: '8rem',
              '&:hover': {
                backgroundColor: '#EF444455',
              }
            }}>
              Delete
            </Button>
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' flexWrap='wrap' gap={1} marginBottom={1}>
          <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.6rem', color: "#292524" }}>Task Group Details</Typography>
          <Box display='flex' flexDirection='column' flexWrap='wrap' gap={0.5}>
            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>DESCRIPTION</Typography>
            <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#292524" }}>{singleTask.task_desc}</Typography>
          </Box>

        </Box>
        <Box display="flex" flexWrap='wrap' marginBottom={1}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>TASK GROUP ID</Typography>
            <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{singleTask.task_id}</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>DATE CREATED</Typography>
            <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{formatDateTimeField(singleTask.created_at)}</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '1.2rem', color: "#A8A29E" }}>LAST EDITED</Typography>
            <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1.4rem', color: "#203447" }}>{formatDateTimeField(singleTask.updated_at)}</Typography>
          </Box>
        </Box>
        <Divider sx={innerDividerStyle} />
        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.6rem', color: "#292524", marginBottom: '0.5rem' }}>Associated Pipelines</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
          {pipelinesData?.length > 0 && this.sortDataByLabel(pipelinesData).map((pipeline, index) => {
            return <Box
              key={index}
              sx={{
                border: '0.1rem solid #1A4A4266',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                borderRadius: "0.7rem",
                padding: "0.6rem 1.2rem",
                width: '10rem',
                fontSize: "1.4rem",
                fontWeight: 400,
                color: "#203447",
              }}
            >{pipeline.label}</Box>
          })}
        </Box>
        <Divider sx={innerDividerStyle} />
        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.6rem', color: "#292524", marginBottom: '0.5rem' }}>Individuals</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
          {singleTask.individualUser?.length > 0 && singleTask.individualUser.map((singleUser, index) => {
            return <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: "center",
                gap: '1rem',
                border: '0.1rem solid #1A4A4266',
                backgroundColor: "white",
                borderRadius: "0.7rem",
                padding: "0.6rem 2rem 0.6rem 1rem",
                fontSize: "1.4rem",
                fontWeight: 400,
                color: "#203447",
                maxWidth: '14rem'
              }}
            > <img
                src={singleUser.source}
                style={{ width: '18px', height: '18px', borderRadius: '50%', objectFit: 'cover' }}
              />
              {singleUser.user_name}</Box>
          })}
        </Box>
        <Divider sx={innerDividerStyle} />
        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.6rem', color: "#292524", marginBottom: '0.5rem' }}>Levels</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "1rem", marginBottom: "2rem" }}>
          {levelsData?.length > 0 && this.sortDataByLabel(levelsData).map((level, index) => {
            return <Box
              key={index}
              sx={{
                border: '0.1rem solid #1A4A4266',
                display: "flex",
                gap: '1rem',
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: "0.7rem",
                padding: "0.6rem 1.2rem",
                width: '10rem',
                fontSize: "1.4rem",
                fontWeight: 400,
                color: "#203447",
              }}
            ><CheckCircleOutlineIcon style={{ fontSize: '1.6rem' }} />
              {level.label}</Box>
          })}
        </Box>
        <Modal
          open={isDeleteTask}
          onClose={this.toggleDeleteTask}
          aria-labelledby="delete-stage-modal"
          aria-describedby="delete-stage-form"
        >
          <Box sx={taskDetailsModalStyle}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Typography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '2rem' }}>Delete Task Group</Typography>
              <IconButton onClick={this.toggleDeleteTask}>
                <CloseIcon style={{ height: "2rem", width: '2rem' }} />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem", padding: 0, width: '100%' }} />
            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h6" style={{ fontSize: '1.2rem' }}>{`Are you sure want to delete stage: ${singleTask.task_id} ?`}</Typography>
            </Box>
            <Box component="form" noValidate autoComplete="off" onSubmit={this.handleDeleteTask}>
              <Box display="flex" justifyContent="center" gap={2} mt={3}>
                <Button onClick={this.toggleDeleteTask} variant="contained" sx={{
                  backgroundColor: '#1A4A421A',
                  color: '#1A4A4299',
                  padding: '1rem 0.8rem',
                  fontWeight: 700,
                  borderRadius: '0.8rem',
                  textTransform: 'none',
                  fontSize: '1.2rem',
                  width: '10rem',
                  '&:hover': {
                    backgroundColor: '#F5FEFD',
                  }
                }}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" sx={{
                  backgroundColor: '#1A4A42',
                  color: 'white',
                  padding: '1rem 0.8rem',
                  fontWeight: 700,
                  borderRadius: '0.8rem',
                  textTransform: 'none',
                  fontSize: '1.2rem',
                  width: '10rem',
                  '&:hover': {
                    backgroundColor: '#1B5C54',
                  }
                }}>
                  Delete
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const detailAccountGroupContainer = {
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  fontFamily: "Raleway, sans-serif",
  boxSizing: "border-box",
  minHeight: 'calc(100vh - 2.5rem)'
};
const taskDetailDividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};
const innerDividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A421A",
};
const taskDetailsModalStyle = {
  position: 'absolute',
  borderRadius: '0.8rem',
  boxShadow: 2,
  transform: 'translate(-50%, -50%)',
  width: '40rem',
  top: '50%',
  left: '50%',
  bgcolor: 'background.paper',
  p: 3,
};
// Customizable Area End
